import React, { useContext } from 'react';
import PhoneInput from 'react-phone-number-input';

import { FormContext } from '../context';

import 'react-phone-number-input/style.css';
import './phone.css';

import loadComponents from '../../Loadable';

const Label = loadComponents('form-label');

export default function Phone({ field }) {
  const { handleChange } = useContext(FormContext);
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor={field.id}>
      <Label label={field.label} required={field.required} />
      <PhoneInput
        defaultCountry="US"
        value={field.value}
        onChange={val =>
          handleChange({ target: { name: field.name, value: val } })
        }
        required={field.required}
      />
    </label>
  );
}
